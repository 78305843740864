import { Text, Button } from '@ftdr/blueprint-components-react';
import clsx from 'clsx';
import styles from './prices.module.css';
import { BASE_URL_PREFIX } from '../../shared/constants';
import { IHomepagePrices } from '../../interfaces/zesty';
import { IMarket } from '../../state/pages.context';
import { getHyphenatedText, getUrlQueryString } from '../../shared/helpers';
import { useRouter } from 'next/router';

function Prices({
    data: { title, linktext, text_field },
    userMarket: { name, id },
}: {
    data: IHomepagePrices;
    userMarket: IMarket;
}) {
    const router = useRouter();
    const checkMarketURL = () => {
        const hasMarket = Object.keys(router.query).find(
            (key) => key === 'superm',
        );
        return hasMarket ? '' : '-r';
    };

    const URLParams: Record<string, string | number> = {};

    const queryString = router.asPath.split('?')[1]
        ? router.asPath.split('?')[1]
        : '';

    Object.entries(router.query).forEach(([key, value]) => {
        if (queryString.includes(key) && key != 'superm')
            URLParams[key] = `${value}`;
    });

    const URLRecords = getUrlQueryString(URLParams);

    const pricesUrl = `${BASE_URL_PREFIX}/pricing-${getHyphenatedText(
        name ? name.replaceAll(' / ', '-') : '',
    )}${checkMarketURL()}/pr/${id}${URLRecords}`;

    return (
        <div className={clsx('pt-20 pb-16 mt-20', styles['prices-component'])}>
            <Text
                variant="heading-03"
                color="white"
                className="text-center text-6 uppercase"
            >
                {title}
            </Text>
            <Text
                color="white"
                className="text-center text-left mt-3 mb-7 max-w-xs mx-auto"
            >
                {text_field}
            </Text>
            <Button
                className={clsx(styles['prices-button'])}
                variant="outlined"
                color="secondary"
                label={linktext}
                href={pricesUrl}
            />
        </div>
    );
}

export { Prices };
