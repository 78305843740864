import { Text, Button } from '@ftdr/blueprint-components-react';
import { getBookingLink } from '../../shared/helpers';
import { PagesContext } from '../../state/pages.context';
import { useContext } from 'react';
import { HERO_BACKGROUND_SRC } from '../../shared/constants';
import styles from './hero.module.css';
import { useRouter } from 'next/router';

function Hero() {
    const { userMarket } = useContext(PagesContext);

    const router = useRouter();

    const queryString = router.asPath.split('?')[1]
        ? router.asPath.split('?')[1]
        : '';

    const URLParams: Record<string, string | number> = {};

    Object.entries(router.query).forEach(([key, value]) => {
        if (queryString.includes(key) && key != 'superm') {
            URLParams[key] = `${value}`;
        }
    });

    return (
        <div>
            <header>
                <div
                    className="w-full bg-center bg-cover"
                    style={{
                        backgroundImage: `url(${HERO_BACKGROUND_SRC})`,
                    }}
                >
                    <div
                        className={`flex items-center justify-center w-full h-full`}
                    >
                        <div className="text-center">
                            <div
                                className={`container px-4 mx-auto ${styles['container']}`}
                            >
                                <div className="max-w-4xl mx-auto text-center">
                                    <Text
                                        variant="heading-02"
                                        className={`${styles['header']} uppercase text-white mb-1 md:mb-4 text-8 md:text-10 mt-4 md:mt-0`}
                                    >
                                        Home Services On-Demand
                                    </Text>
                                    <Button
                                        color="secondary"
                                        label="Book an Appointment"
                                        variant="filled"
                                        size="large"
                                        className="mt-1 normal-case"
                                        href={getBookingLink({
                                            market: userMarket,
                                            params: URLParams,
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

export { Hero };
