import { Link } from '@ftdr/blueprint-components-react';

export const GUARANTEE_TEXT = (
    <div>
        We stand behind the quality of our Pros. If you are not reasonably
        satisfied with the Pro who is sent to your home, we will seek to rectify
        the situation. This is our commitment to you.{' '}
        <Link
            underline="always"
            target="_blank"
            href="/frontdoorpro-agreement"
            color="interactive"
        >
            Terms and Conditions Apply
        </Link>
    </div>
);

export const GUARANTEE_ROLLOVER = {
    label: `Frontdoor Pro Guarantee.`,
    content: GUARANTEE_TEXT,
    mapper: 'proconnect_guarantee',
};
