import { Link, Text } from '@ftdr/blueprint-components-react';
import styles from './hiw.module.css';
import clsx from 'clsx';
import { GUARANTEE_ROLLOVER, GUARANTEE_TEXT } from './constants';
import { replaceWithPrice } from '../../shared/helpers';
import { IServiceHIW, IFeeContent } from '../../interfaces/zesty';
import { getRolloveredText } from '../shared';

interface IProps {
    serviceHIW: IServiceHIW;
    feeContent?: IFeeContent;
    surcharge?: string;
    isFTDRTheme?: boolean;
}

function HowItWorks({
    serviceHIW,
    feeContent,
    surcharge = '',
    isFTDRTheme,
}: IProps) {
    const amount = surcharge ? `$${surcharge}` : ' ';
    const step_2_Description = replaceWithPrice(
        serviceHIW.step_2_description,
        amount,
    );

    const ftdrStep1Description = `
    Pick a date and time window when you want your background checked Pro to arrive. Manage your appointment on the Frontdoor App.
    `;

    return (
        <div
            id="hiwSection"
            className={clsx(
                `mx-auto md:mt-10 ${styles['hiw-section']}`,
                isFTDRTheme && `hiw-section-ftdr ${styles['hiw-section-ftdr']}`,
            )}
        >
            <Text
                variant="heading-03"
                color="secondary"
                className={clsx(
                    'text-center text-6 mb-6 md:mt-10 md:mb-8',
                    !isFTDRTheme && 'mt-20',
                    isFTDRTheme && 'mt-10 md:mt-20 md:mb-0',
                )}
            >
                How it works
            </Text>
            <div className="w-full relative">
                <div
                    className={clsx(
                        'absolute hidden md:block h-0',
                        styles['separator'],
                        isFTDRTheme && styles['separator-ftdr'],
                    )}
                ></div>
                <div>
                    <div className="md:flex">
                        <div
                            className={clsx(
                                'flex-1 pt-4 px-3',
                                isFTDRTheme && 'md:pt-0',
                            )}
                        >
                            <div
                                className={clsx(
                                    'text-center',
                                    !isFTDRTheme && 'h-20',
                                    isFTDRTheme && 'md:h-20',
                                )}
                            >
                                <img
                                    src={
                                        isFTDRTheme
                                            ? '/images/hiw2-1.png'
                                            : '/images/hiw-1.png'
                                    }
                                    alt="hiw_icon"
                                    style={{
                                        width: isFTDRTheme ? '90px' : '120px',
                                    }}
                                    className={clsx(
                                        'm-auto',
                                        isFTDRTheme && 'mt-1 md:mt-2',
                                    )}
                                />
                            </div>
                            <div className="flex justify-center">
                                <span
                                    className={clsx(
                                        'rounded-full text-center border-2 text-primary bg-white relative z-1 flex justify-center text-5 font-bold hidden md:block',
                                        styles['border-fd-teal'],
                                        !isFTDRTheme && 'w-10 h-10 pt-2',
                                        isFTDRTheme &&
                                            `w-8 h-8 my-3 ${styles['hiw-steps-numbers-ftdr']}`,
                                    )}
                                >
                                    1
                                </span>
                            </div>
                            <Text
                                variant="heading-04"
                                color="secondary"
                                className={clsx(
                                    'text-center text-5 md:pt-5',
                                    !isFTDRTheme && 'pt-0 pb-3',
                                    isFTDRTheme && 'py-6 md:pb-7',
                                )}
                            >
                                {serviceHIW.step_1_title}
                            </Text>
                            <Text
                                className={clsx(
                                    'mb-6 text-center',
                                    isFTDRTheme &&
                                        `px-4 ${styles['hiw-description-ftdr']}`,
                                )}
                            >
                                {getRolloveredText(
                                    isFTDRTheme
                                        ? ftdrStep1Description
                                        : serviceHIW.step_1_description,
                                )}
                            </Text>
                        </div>
                        <div
                            className={clsx(
                                'flex-1 pt-4 px-3',
                                isFTDRTheme && 'md:pt-0',
                            )}
                        >
                            <div
                                className={clsx(
                                    'text-center',
                                    !isFTDRTheme && 'h-20',
                                    isFTDRTheme && 'md:h-20',
                                )}
                            >
                                <img
                                    src={
                                        isFTDRTheme
                                            ? '/images/hiw2-2.png'
                                            : '/images/hiw-2.png'
                                    }
                                    alt="hiw_icon"
                                    style={{
                                        width: isFTDRTheme ? '132px' : '150px',
                                    }}
                                    className={clsx(
                                        'm-auto',
                                        isFTDRTheme && 'mt-2',
                                    )}
                                />
                            </div>
                            <div className="flex justify-center">
                                <span
                                    className={clsx(
                                        'rounded-full text-center border-2 text-primary bg-white relative z-1 flex justify-center text-5 font-bold hidden md:block',
                                        styles['border-fd-teal'],
                                        !isFTDRTheme && 'w-10 h-10 pt-2',
                                        isFTDRTheme &&
                                            `w-8 h-8 my-3 ${styles['hiw-steps-numbers-ftdr']}`,
                                    )}
                                >
                                    2
                                </span>
                            </div>
                            <Text
                                variant="heading-04"
                                color="secondary"
                                className={clsx(
                                    'text-center text-5 md:pt-5',
                                    !isFTDRTheme && 'pt-0 pb-3',
                                    isFTDRTheme && 'pt-5 pb-7',
                                )}
                            >
                                {serviceHIW.step_2_title}
                            </Text>
                            <Text
                                className={clsx(
                                    'mb-6 text-center',
                                    isFTDRTheme &&
                                        styles['hiw-description-ftdr'],
                                )}
                            >
                                {getRolloveredText(
                                    step_2_Description,
                                    feeContent,
                                )}
                            </Text>
                        </div>
                        <div
                            className={clsx(
                                'flex-1 pt-4 px-3',
                                isFTDRTheme && 'md:pt-0',
                            )}
                        >
                            <div
                                className={clsx(
                                    'text-center',
                                    !isFTDRTheme && 'h-20',
                                    isFTDRTheme && 'md:h-20',
                                )}
                            >
                                <img
                                    src={
                                        isFTDRTheme
                                            ? '/images/hiw2-3.png'
                                            : '/images/hiw-3.png'
                                    }
                                    alt="hiw_icon"
                                    style={{
                                        width: isFTDRTheme ? '73px' : '90px',
                                    }}
                                    className={clsx(
                                        'm-auto',
                                        isFTDRTheme && 'mt-2',
                                    )}
                                />
                            </div>
                            <div className="flex justify-center">
                                <span
                                    className={clsx(
                                        'rounded-full text-center border-2 text-primary bg-white relative z-1 flex justify-center text-5 font-bold hidden md:block',
                                        styles['border-fd-teal'],
                                        !isFTDRTheme && 'w-10 h-10 pt-2',
                                        isFTDRTheme &&
                                            `w-8 h-8 my-3 ${styles['hiw-steps-numbers-ftdr']}`,
                                    )}
                                >
                                    3
                                </span>
                            </div>
                            <Text
                                variant="heading-04"
                                color="secondary"
                                className={clsx(
                                    'text-center text-5 md:pt-5',
                                    !isFTDRTheme && 'pt-0 pb-3',
                                    isFTDRTheme && 'pt-5 pb-7',
                                )}
                            >
                                {serviceHIW.step_3_title}
                            </Text>
                            <Text
                                className={clsx(
                                    'text-center inline-block',
                                    !isFTDRTheme && 'mb-6',
                                    isFTDRTheme &&
                                        styles['hiw-description-ftdr'],
                                )}
                            >
                                {getRolloveredText(
                                    serviceHIW.step_3_description,
                                    GUARANTEE_ROLLOVER,
                                )}
                            </Text>
                            <Text className="mb-5 text-center hidden">
                                <span>{GUARANTEE_TEXT}</span>
                            </Text>
                        </div>
                    </div>
                    {isFTDRTheme && (
                        <Text
                            className={clsx(
                                `pt-10 pb-9 md:pt-6 md:pb-12 text-center px-4 mx-auto ${styles['disclaimer-ftdr']}`,
                            )}
                        >
                            Services offered by Frontdoor Pro and performed by a
                            Frontdoor Pro independent service contractor. For
                            license numbers, please visit{' '}
                            <Link href="/contractor-licenses">this page</Link>{' '}
                            on Frontdoor Pro&apos;s website.
                        </Text>
                    )}
                </div>
            </div>
        </div>
    );
}

export { HowItWorks };
