import type { NextPage } from 'next';
import { Footer } from '../components/footer';
import { ServicesTypes } from '../components/service-types';
import { useContext, useEffect, useState } from 'react';
import { useGeolocation, useTealium } from '../hooks';
import { DefaultHeader } from '../components/default-header';
import { PagesContext } from '../state/pages.context';
import { Hero } from '../components/hero';
import { HowItWorks } from '../components/how-it-works';
import { useRouter } from 'next/router';
import {
    getGuarantees,
    getHomepagePrices,
    getHowItWorks,
} from '../services/zesty';
import {
    IHomepagePrices,
    IServiceGuarantees,
    IServiceHIW,
    ISpecials,
} from '../interfaces/zesty';
import { getReviews, getFooterLinks } from '../services/zesty';
import { Reviews } from '../components/reviews/reviews';
import { Guarantee } from '../components/guarantee';
import { IFooterLink } from '../interfaces/footer';
import { Prices } from '../components/prices';
import { ISEMPageUrlParams } from '../interfaces/shared-props';
import { SESSION_STORAGE_KEYS } from '../shared/constants';

interface IProps {
    readonly howItWorks: IServiceHIW;
    readonly footerLinks: IFooterLink[];
    readonly homepagePrices: IHomepagePrices;
    readonly specials: ISpecials;
    query: Partial<ISEMPageUrlParams>;
}

const Home: NextPage<IProps> = ({
    howItWorks,
    homepagePrices,
    footerLinks,
}) => {
    const { query } = useRouter();
    const { superm, sid } = query;
    const { trackView } = useTealium();
    const { userMarket, setUserMarket, marketsServices } =
        useContext(PagesContext);
    const [reviews, setReviews] = useState<any[]>([]);
    const [serviceGuarantees, setServiceGuarantees] =
        useState<IServiceGuarantees>();
    const { market: geoMarket } = useGeolocation();

    useEffect(() => {
        if (
            geoMarket &&
            (!superm ||
                marketsServices.length < 1 ||
                !marketsServices.find((value) => value.marketId === superm))
        ) {
            setUserMarket(geoMarket);
        }
    }, [geoMarket, setUserMarket]);

    useEffect(() => {
        const { href, pathname, search } = window.location;
        trackView({
            virtual_page_path: `${pathname}${search}`,
            virtual_page_URL: href,
        });
    }, [trackView]);

    useEffect(() => {
        if (superm && !Array.isArray(superm)) {
            setUserMarket({
                id: superm,
                name:
                    marketsServices.find((value) => value.marketId === superm)
                        ?.marketName || '',
            });
        } else {
            //we need to set an initail value while we are getting the market
            setUserMarket({
                id: '',
                name: '',
            });
        }
    }, [superm]);

    useEffect(() => {
        getReviews('1013')
            .then((response) => {
                setReviews(response ?? []);
            })
            .catch((err) => {
                console.error('There was an error geting the review: ', err);
            });

        // The page is supposed to have the serviceId on query params?
        getGuarantees('1013')
            .then((response) => {
                setServiceGuarantees(response);
            })
            .catch((err) => {
                console.error(
                    'There was an error geting the services guarantees: ',
                    err,
                );
            });
    }, []);

    useEffect(() => {
        if (!sid) {
            let _sid = '236';
            const refType = '1022';

            const isOrganicSearch = [
                'google.com',
                'bing.com',
                'yahoo.com',
            ].some((x) => document.referrer.includes(x));
            if (isOrganicSearch) {
                _sid = '235';
            }

            const bookingParams = JSON.stringify({
                sid: _sid,
                refType,
            });
            sessionStorage.setItem(
                SESSION_STORAGE_KEYS.homeBookingParams,
                bookingParams,
            );
        }
    }, [sid]);

    return (
        <div className="w-full">
            <DefaultHeader
                shouldIndexPage
                pageTitle="Home Repair Service Providers On Demand"
            />

            <main>
                <Hero />

                <HowItWorks serviceHIW={howItWorks} />

                <Reviews
                    headline={'Frontdoor Pro Reviews'}
                    reviews={reviews}
                    backgroundColorProp={'#F7F7F7'}
                />

                <ServicesTypes />

                <Prices data={homepagePrices} userMarket={userMarket} />

                <div>
                    {serviceGuarantees &&
                    serviceGuarantees?.guarantees?.length >= 1 ? (
                        <div>
                            <Guarantee {...serviceGuarantees} />
                        </div>
                    ) : null}
                </div>
            </main>

            <Footer links={footerLinks} market={userMarket} />
        </div>
    );
};

export async function getServerSideProps() {
    const howItWorks = await getHowItWorks();
    const footerLinks = await getFooterLinks();
    const homepagePrices = await getHomepagePrices();

    return {
        props: {
            footerLinks,
            howItWorks,
            homepagePrices,
        },
    };
}

export default Home;
