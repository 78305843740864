import { useContext } from 'react';
import { Text } from '@ftdr/blueprint-components-react';
import Link from 'next/link';
import styles from './service-types.module.css';
import { PagesContext } from '../../state/pages.context';
import { getHyphenatedText, getUrlQueryString } from '../../shared/helpers';
import { IServiceType } from '../../interfaces/shared';

import clsx from 'clsx';
import { useRouter } from 'next/router';

function ServicesTypes() {
    const { marketsServices, userMarket } = useContext(PagesContext);
    // Limit the services to show
    const servicesAvailable = ['1013', '1004', '1023'];

    const router = useRouter();

    const URLParams: Record<string, string | number> = {};

    const queryString = router.asPath.split('?')[1]
        ? router.asPath.split('?')[1]
        : '';

    Object.entries(router.query).forEach(([key, value]) => {
        if (queryString.includes(key) && key != 'superm')
            URLParams[key] = `${value}`;
    });

    const URLRecords = getUrlQueryString(URLParams);

    const getServiceBookingLink = (serviceType: IServiceType) => {
        if (validateServices()) {
            return `/${getHyphenatedText(serviceType.name)}-${getHyphenatedText(
                userMarket.name,
            )}/service/${userMarket.id}-${
                serviceType.id
            }?${URLRecords}&refType=1014`;
        } else {
            return `/${getHyphenatedText(serviceType.name)}-${getHyphenatedText(
                userMarket.name,
            )}/service/${userMarket.id}${URLRecords}`;
        }
    };

    const validateServices = () => {
        const services = marketsServices
            .filter((market) => market.marketName === userMarket?.name)
            .map((market) => market.services.length);
        return services.length !== 0;
    };

    const checkServiceType = (serviceId: string) =>
        servicesAvailable.includes(serviceId);

    return (
        <>
            <div className="mb-7 md:mb-0 mx-2">
                <Text
                    variant="heading-03"
                    color="secondary"
                    className="text-center text-6 uppercase leading-6 mt-11 mb-2 md:mb-4"
                >
                    SERVICES WE PROVIDE
                    {validateServices() ? (
                        <>
                            {' '}
                            IN THE {userMarket?.name?.toLocaleUpperCase()} AREA
                        </>
                    ) : null}
                </Text>
                {!validateServices() ? (
                    <Text
                        color="primary"
                        className={clsx(
                            'mb-1 text-center italic md:mb-4',
                            styles['subtitle'],
                        )}
                    >
                        Availability Varies by Market
                    </Text>
                ) : null}
            </div>

            <div
                className={clsx(
                    'flex',
                    'justify-center',
                    'gap-6',
                    'cursor-pointer',
                    styles['mobile-service-types'],
                )}
            >
                {marketsServices
                    .filter((market) => {
                        if (validateServices()) {
                            return (
                                market.marketName.toLowerCase() ===
                                userMarket?.name?.toLowerCase()
                            );
                        }

                        return (
                            market.marketId === '7' &&
                            market.services.length >= 3
                        );
                    })
                    .map((market) => (
                        <>
                            {market.services
                                .filter((value) => checkServiceType(value.id))
                                .map((service) => (
                                    <Link
                                        key={service.id}
                                        href={getServiceBookingLink({
                                            id: service.id,
                                            name: service.name,
                                        })}
                                        passHref
                                    >
                                        <div className="flex flex-col items-center justify-center gap-6 cursor-pointer">
                                            <>
                                                {service.tileImage ? (
                                                    <img
                                                        className={
                                                            styles[
                                                                'service-type-image'
                                                            ]
                                                        }
                                                        src={`${service.tileImage}`}
                                                        alt="ProConnect Service Type"
                                                        style={{
                                                            width: '240px',
                                                            height: '190px',
                                                        }}
                                                    />
                                                ) : null}

                                                <Text
                                                    variant="heading-06"
                                                    color="primary"
                                                    className="text-center text-3 -mt-4 tracking-tighter"
                                                >
                                                    {service.name}
                                                </Text>
                                            </>
                                        </div>
                                    </Link>
                                ))}
                        </>
                    ))}
            </div>
        </>
    );
}

export { ServicesTypes };
